export const projectsData = [
  {
    id: 1,
    title: "meowdopt",
    image: "./../assets/images/meowdopt.png",
    link: "https://meowdopt.herokuapp.com/",
    tech: ["SASS", "Chakra UI", "React", "Sweetalert2"],
    code: "https://github.com/aramatsolrac/meowdopt",
  },
  {
    id: 2,
    title: "meowdopt API",
    image: "./../assets/images/meowdopt-api.png",
    link: "https://api-meowdopt.herokuapp.com/",
    tech: ["Node.js", "Express", "Knex", "MySQL"],
    code: "https://github.com/aramatsolrac/meowdopt-API",
  },
  {
    id: 3,
    title: "InStock",
    image: "./../assets/images/inStock.png",
    link: "https://aramatsolrac.github.io/InStock/",
    tech: ["SASS", "React", "Node", "Express"],
    code: "Private Repo",
  },
  {
    id: 4,
    title: "Advice Generator",
    image: "./../assets/images/inStock.png",
    link: "https://advice-generator-project.herokuapp.com/",
    tech: ["SASS", "React", "Node", "Express"],
    code: "https://github.com/aramatsolrac/advice-generator-app",
  },
  {
    id: 5,
    title: "BrainFlix",
    image: "./../assets/images/weather-dashboard.png",
    link: "https://tamara-carlos-brainflix.herokuapp.com/",
    tech: ["SASS", "React", "Node", "Express"],
    code: "Private Repo",
  },
  {
    id: 6,
    title: "BrainFlix API",
    image: "./../assets/images/brainflix-api.png",
    link: "https://brainflix-api.herokuapp.com/",
    tech: ["Node", "Express"],
    code: "Private Repo",
  },
  {
    id: 7,
    title: "Movies Search",
    image: "./../assets/images/movies-search.png",
    link: "https://aramatsolrac.github.io/movies-search/",
    tech: ["SASS", "JavaScript", "TMDB API"],
    code: "https://github.com/aramatsolrac/moviesAPI",
  },
  {
    id: 8,
    title: "Bandsite",
    image: "./../assets/images/bandsite.png",
    link: "https://aramatsolrac.github.io/bandsite/",
    tech: ["SASS", "JavaScript", "API", "Sweetalert2"],
    code: "Private Repo",
  },
  {
    id: 9,
    title: "Travel Site",
    image: "./../assets/images/travel-site.png",
    link: "https://aramatsolrac.github.io/BrainStation/travelsite/",
    tech: ["HTML", "CSS", "BEM"],
    code: "Private Repo",
  },
  {
    id: 10,
    title: "Coffee Shop",
    image: "./../assets/images/coffee-shop.png",
    link: "https://aramatsolrac.github.io/BrainStation/coffee_shop/",
    tech: ["HTML", "SASS", "CSS", "BEM"],
    code: "Private Repo",
  },
  {
    id: 11,
    title: "Spacestagram",
    image: "./../assets/images/space.png",
    link: "https://aramatsolrac.github.io/spacestagram/",
    tech: ["HTML", "CSS", "JavaScript", "Nasa API"],
    code: "https://github.com/aramatsolrac/spacestagram",
  },
  {
    id: 12,
    title: "Alura Challenge",
    image: "./../assets/images/alura.png",
    link: "https://aramatsolrac.github.io/alura-challenge-front-end/index.html",
    tech: ["HTML", "CSS", "JavaScript", "Firebase"],
  },
];
